<template>
    <v-select :items="list_data" ref="myInput" :loading="loading" :disabled="loading" :value="value" @input="handleInput()" :label="label" :name="name" :rules="formRules"
        outlined dense rounded>
    </v-select>
</template>
<script>

import {GetRef,GetAny,PostAny} from '@/globalFunction';

    export default {
        data() {
            return {
                 formRules: [],
                 content:this.value,
                 setUrlRef:this.url_ref,
                 filterUrlRef:this.filter,
                 list_data:[],
                 loading:true
            };
        },
        methods: {
            setFormRules() {
                let validate = this.validation; 
                let tmp_validate = [];                
                validate.forEach(rule => {
                    switch (rule) {
                        case 'required':
                            tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                        break;
                    }
                });

                 this.formRules = tmp_validate;
            },
            handleInput (e) {
                this.$emit('input', this.$refs.myInput.value)
            }
        },
        mounted() {
           this.setFormRules();
           this.loading = true;
            GetRef(this.setUrlRef,this.filterUrlRef).then(data => {
              this.list_data = data
              this.loading = false
            });
        },
        props: ['label', 'name', 'value','validation','url_ref','filter'],
        name: 'formInput',
    }
</script>